import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';
import { ExpandablePanel, KeyValueList } from '../styles';
import { FieldKeys, SectionProps } from '../types';

export const fieldKeys: FieldKeys[] = [
  'first_name',
  'middle_name',
  'last_name',
  'date_of_birth',
  'social_security',
  'location',
  'postal_code',
  'phone',
  'email',
  'custom_id',
  'drivers_license',
  'candidate_created_at',
  'invitation_sent',
];

// Memoized version of the KeyValueList that does deep comparison of items
const MemoizedKeyValueList = memo(KeyValueList, (prevProps, nextProps) => {
  if (prevProps.items.length !== nextProps.items.length) return false;

  // Compare each item only by ID since key and value can be a React node
  return prevProps.items.every(
    (item: { id: string }, index: number) =>
      item.id === nextProps.items[index].id,
  );
});

const CandidateInformation: React.FC<SectionProps> = ({ fieldKeysToItems }) => {
  const { t } = useTranslation();
  const title = t(`${namespace}:report.overview.candidate-information.title`);

  const candidateInformation = useMemo(
    () => fieldKeysToItems(fieldKeys),
    [fieldKeysToItems],
  );

  return candidateInformation.length > 0 ? (
    <ExpandablePanel
      data-pendo-id='candidate-information-panel'
      initialExpanded
      title={title}
      style={{ marginTop: '2rem' }}
    >
      <MemoizedKeyValueList
        items={candidateInformation}
        data-testid='overview-candidate-information'
      />
    </ExpandablePanel>
  ) : null;
};

export default CandidateInformation;
