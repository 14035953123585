import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { QueryResult } from 'react-query';
import { filterPackages } from 'utils';
import {
  ORDER_BACKGROUND_CHECK_EVENT_NAMES,
  useTrackEvent,
} from 'components/Packages/Amplitude/analytics';
import { useUser } from 'context/CurrentUser';
import { Package, Report, Screening } from 'types';
import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import { useList, usePackages } from 'api/packages';
import { useList as useNodePackages } from 'api/nodePackage';
import {
  useOrderBackgroundCheckContext,
  actionTypes,
  MULTIPLE_PEOPLE,
} from '../OrderBackgroundCheck/Context';
import { availableForManualBulk } from '../OrderBackgroundCheck/SelectYourPackage/SelectPackage';
import WideChoiceCards from '../OrderBackgroundCheck/SelectYourPackage/WideChoiceCards';

const Container = styled.div`
  width: 744px;
`;

const Header = styled.div`
  width: 744px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.brandNavy4};
  margin-bottom: 16px;
`;

const SearchContainer = styled(M.Search)`
  width: 300px;
  margin-bottom: 16px !important;
  .cds--search-input {
    color: ${colors.brandSlate6} !important;
    border-bottom: 1px solid ${colors.brandSlate7} !important;
  }
  .cds--search-input::placeholder {
    color: ${colors.brandSlate6};
  }
  .cds--search-magnifier-icon {
    fill: ${colors.brandSlate6};
  }
`;

const PaginationContainer = styled(M.Pagination)`
  display: flex;
  justify-content: center;
`;

const parsePackagesResponse = (
  response: QueryResult<{ [key: string]: any }>,
) => {
  const packages = response?.data as Package[];
  const packagesLoading = response?.isLoading as boolean;
  return {
    packages,
    packagesLoading,
  };
};

// Depending on what params are sent to the api, the report object will have different keys
// x_id(s) will be a string[] & the other will be an object
const REPORT_KEYS_TO_CHECK = [
  'education_verification_id',
  'education_verification',
  'employment_verification_id',
  'employment_verification',
  'personal_reference_verifications',
  'personal_reference_verifications_ids',
  'professional_license_verifications',
  'professional_license_verifications_ids',
  'professional_reference_verifications',
  'professional_reference_verifications_ids',
  'motor_vehicle_report_id',
];

// Map the report keys to screening types
const REPORT_KEYS_TO_CHECK_MAP: { [key: string]: ScreeningType } = {
  education_verification_id: ScreeningType.education_verification,
  education_verification: ScreeningType.education_verification,
  employment_verification_id: ScreeningType.employment_verification,
  employment_verification: ScreeningType.employment_verification,
  personal_reference_verifications:
    ScreeningType.personal_reference_verification,
  personal_reference_verifications_ids:
    ScreeningType.personal_reference_verification,
  professional_license_verifications:
    ScreeningType.professional_license_verification,
  professional_license_verifications_ids:
    ScreeningType.professional_license_verification,
  professional_reference_verifications:
    ScreeningType.professional_reference_verification,
  professional_reference_verifications_ids:
    ScreeningType.professional_reference_verification,
  motor_vehicle_report_id: ScreeningType.motor_vehicle_report,
};

export const getScreeningsToOmit = (reports: Report[]) => {
  if (!reports.length) return [];

  return REPORT_KEYS_TO_CHECK.filter(keyOption => {
    for (const report of reports) {
      const reportValue = report[keyOption];
      const isValueAnArray = Array.isArray(report[keyOption]);
      if (reportValue || (isValueAnArray && reportValue.length > 0))
        return true;
    }
    return false;
  });
};

export const hasScreening = (
  screenings: Screening[],
  targetScreening: ScreeningType,
): boolean => {
  return !!screenings?.find(screening => screening.type === targetScreening);
};

export const packageHasReportScreenings = (
  pckg: Package,
  screeningsToOmit: string[],
): boolean => {
  if (screeningsToOmit.length === 0) return false;

  return !!screeningsToOmit?.find((screening: string) => {
    const mappedScreening = REPORT_KEYS_TO_CHECK_MAP[screening];
    return hasScreening(pckg.screenings ?? [], mappedScreening);
  });
};

// If any previous reports contain values for the keys in REPORT_KEYS_TO_CHECK,
// packages with any of those screenings should be filtered out to prevent re-ordering
// TODO: This is not a performant function, look to optimize this post soft launch
export const filterPackageByReportScreenings = (
  reports: Report[],
  packages: Package[],
) => {
  const screeningsToOmit = getScreeningsToOmit(reports);

  return packages.filter(pckg => {
    const hasReportScreenings = packageHasReportScreenings(
      pckg,
      screeningsToOmit,
    );
    // If we find a screening that's matching, return false so .filter can remove it from the packages
    if (hasReportScreenings) return false;
    return true;
  });
};

type SelectPackageProps = {
  segmentation_enabled: boolean;
  hierarchy_present: boolean;
  setLoadingPackages: Function;
  reportPackageSlug?: string;
  reportPackageName?: string;
  reports: Report[];
};

const getAdditionalProperties = (
  basePackage: Package,
  additionalProperties: any,
) => {
  const facis = basePackage?.screenings?.find(
    (screening: Screening) => screening.type === 'facis_search',
  );

  const facisSearch = facis ? { facis_search: { subtype: facis.subtype } } : {};

  return {
    ...additionalProperties,
    ...facisSearch,
  };
};

const SelectPackage: React.FC<SelectPackageProps> = ({
  segmentation_enabled,
  hierarchy_present,
  setLoadingPackages,
  reportPackageSlug = '',
  reportPackageName = '',
  reports,
}) => {
  const { state, dispatch, addDataDogError } = useOrderBackgroundCheckContext();
  const currentUser = useUser();
  const {
    account: { id: accountId, show_package_price: showPackagePrice },
  } = currentUser;
  const trackEvent = useTrackEvent();
  const [searchParam, setSearchParam] = useState('');
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [count, setCount] = useState(0);
  const packagesPerPage = 10;
  const params = useMemo(
    () =>
      new URLSearchParams({
        name: reportPackageName,
        with_private: 'true',
      }),
    [reportPackageName],
  );
  const { data: packagesWithPrivate } = usePackages(accountId, params);

  const isAvailableForManualBulk = useCallback(
    (thisPackage: Package): boolean => {
      const screenings = thisPackage?.screenings || [];

      return (
        state.manualBulkUploadType !== MULTIPLE_PEOPLE ||
        screenings.every(screening =>
          availableForManualBulk.has(screening.type),
        )
      );
    },
    [state.manualBulkUploadType],
  );

  const currentNode = state?.node;
  const resp = useList({ accountId });

  const { packages, packagesLoading } = parsePackagesResponse(resp);

  const { data, isLoading: nodeIsLoading } = useNodePackages({
    account: { hierarchy_present, id: accountId },
    customId: currentNode.custom_id,
    includeAccountId: true,
  });

  const nodePackages = useMemo(() => {
    return data?.packages;
  }, [data]);

  const selectablePackages = useMemo(() => {
    // handle selectable packages for a segmentation account
    if (segmentation_enabled) {
      // with hierarchy present, use the nodePackages for the node to filter packages
      if (hierarchy_present && currentNode) {
        return packages?.filter(pkg => nodePackages?.includes(pkg.slug));
      }
      if (!hierarchy_present) {
        return packages;
      }
    }
    return packages;
  }, [
    segmentation_enabled,
    packages,
    hierarchy_present,
    currentNode,
    nodePackages,
  ]);

  const packageOnReport: Package = useMemo(
    () =>
      packagesWithPrivate?.find(
        (_package: Package) => _package.slug === reportPackageSlug,
      ) || ({} as Package),
    [packagesWithPrivate, reportPackageSlug],
  );

  useEffect(() => {
    const additionalProperties = getAdditionalProperties(
      packageOnReport,
      state.additionalProperties,
    );
    dispatch({
      type: actionTypes.SET_OLD_BASE_PACKAGE,
      payload: {
        oldBasePackage: packageOnReport,
        additionalProperties,
      },
    });
    dispatch({
      type: actionTypes.SET_PACKAGE_ON_REPORT,
      payload: { packageOnReport },
    });
    // setLoadingPackages determines whether the skip button on step 2 is enabled/disabled

    if (!packagesLoading) {
      setLoadingPackages(false);
    }
  }, [dispatch, packageOnReport, packagesLoading, setLoadingPackages]);

  if (resp.isError) {
    const errorMessage = resp?.error?.message;
    trackEvent(currentUser, ORDER_BACKGROUND_CHECK_EVENT_NAMES.ERROR_VIEWED, {
      'Page Name': '2. Select a package',
      'Error Name': errorMessage,
    });
    addDataDogError(errorMessage, {}, undefined);
  }

  const filteredPackages = useMemo(() => {
    let filtered =
      state.selectedGeo === 'domestic'
        ? filterPackages(selectablePackages).domestic
        : filterPackages(selectablePackages).international;

    // Filter by program if a program is selected
    filtered = filtered.filter(pckg => {
      if (state.program?.id) return pckg.program_id === state.program?.id;
      return pckg;
    });

    filtered = filterPackageByReportScreenings(reports, filtered);

    setCount(filtered.length);
    return filtered;
  }, [selectablePackages, state.selectedGeo, state.program?.id, reports]);

  const searchedThruPackages: any = useMemo(() => {
    const searchedPackages = filteredPackages?.filter(
      (currentPackage: Package) =>
        currentPackage.name
          ?.toLocaleLowerCase()
          .includes(searchParam.toLocaleLowerCase()),
    );
    setCount(searchedPackages.length);
    return searchedPackages;
  }, [filteredPackages, searchParam]);

  const currentPackages = useMemo(
    () =>
      searchedThruPackages.slice(
        currentPageIndex * packagesPerPage,
        (currentPageIndex + 1) * packagesPerPage,
      ) || [],
    [currentPageIndex, packagesPerPage, searchedThruPackages],
  );

  const handleSearchChange = useCallback(
    (e: any) => {
      if (!state.selectPackageAmplitudeData.searchUsed) {
        dispatch({
          type: actionTypes.SET_SELECT_PACKAGE_AMPLITUDE_DATA,
          payload: {
            selectPackageAmplitudeData: {
              ...state.selectPackageAmplitudeData,
              searchUsed: true,
            },
          },
        });
      }
      setSearchParam(e.target.value);
      setCurrentPageIndex(0);
    },
    [dispatch, state.selectPackageAmplitudeData],
  );

  const handlePageClick = useCallback(
    (pageIndex: number) => {
      setCurrentPageIndex(pageIndex);
      dispatch({
        type: actionTypes.SET_SELECT_PACKAGE_AMPLITUDE_DATA,
        payload: {
          selectPackageAmplitudeData: {
            ...state.selectPackageAmplitudeData,
            numberOfPagesViewed:
              state.selectPackageAmplitudeData.numberOfPagesViewed + 1,
          },
        },
      });
    },
    [dispatch, state.selectPackageAmplitudeData],
  );

  const isBasePackageInCurrentPackages = useMemo(() => {
    if (Object.keys(state.basePackage).length > 0) {
      return (
        currentPackages.filter(
          (_package: Package) => _package.name === state.basePackage.name,
        ).length > 0
      );
    }

    return false;
  }, [currentPackages, state.basePackage]);

  useEffect(() => {
    dispatch({
      type: actionTypes.CONTINUE_BUTTON_DISABLED,
      payload: { continueButtonDisabled: !isBasePackageInCurrentPackages },
    });
  }, [dispatch, isBasePackageInCurrentPackages, state.basePackage]);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_NODE,
      payload: { node: state.node },
    });
  }, [dispatch, state.node]);

  return (
    <Container>
      <Header data-testid='select-your-package-header'>
        Select the package that best meets your needs. You can add more searches
        in the next step.
      </Header>
      <SearchContainer
        value={searchParam}
        type='text'
        onChange={handleSearchChange}
        data-testid='package-search'
        placeholder='Enter a package name'
      />
      {packagesLoading || nodeIsLoading ? (
        <M.LoadingInline description='Loading packages...' />
      ) : (
        <WideChoiceCards
          data-testid='package-wide-choice-card'
          packages={currentPackages}
          showPackagePrice={showPackagePrice}
          segmentation_enabled={segmentation_enabled}
          hierarchy_present={hierarchy_present}
          node={currentNode}
          // eslint-disable-next-line react/jsx-no-bind
          isManualBulk={isAvailableForManualBulk}
        />
      )}
      {count > 10 && currentPackages.length > 0 && (
        <PaginationContainer
          data-testid='package-pagination'
          pageCount={Math.ceil(count / packagesPerPage)}
          selectedIndex={currentPageIndex}
          onPageClick={handlePageClick}
        />
      )}
    </Container>
  );
};

export default SelectPackage;
