import React from 'react';
import { useGetReport, useAdjudicable } from 'api/reports';
import {
  ReportStatuses,
  ADJUDICATION_VALUES,
} from '@dashboard-experience/utils';

export type Props = {};

export const Guard: React.FC<Props> = ({ children }) => {
  const { report: { adjudication, status } = {} } = useGetReport();
  const { isEngageable } = useAdjudicable();

  const displayEngage =
    adjudication !== ADJUDICATION_VALUES.ENGAGED &&
    (adjudication !== ADJUDICATION_VALUES.PRE_ADVERSE_ACTION || isEngageable) &&
    status !== ReportStatuses.PENDING;

  return displayEngage ? <>{children}</> : null;
};

export default Guard;
